import React, { useEffect, useState } from "react";
import { featuredSolutionData } from "../views/apps/solutionSets/enum";
import { Viewer } from "@react-pdf-viewer/core";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiUrl, imgUrl } from "../utils/constant";

const ViewCaseStudy = () => {
  const { slug } = useParams();
  const [whitePaperData, setWhitePaperData] = useState({});
  const [categoryData, setCategoryData] = useState({});
  const navigate = useNavigate();

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getWhitePaperData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user/get-whitepaper/name/${slug}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );

        if (response.data.status) {
          const categoryId = response.data.data.category.id;
          const whitePaper = response.data.data.whitePaper;

          getCategoryData(categoryId, whitePaper.slug);
          postViewPdfData(whitePaper.id);

          setWhitePaperData({
            categoryId: categoryId,
            ...whitePaper,
            isSaved: response.data.data.isSaved,
            whitepaperUrl: response.data.data.whitepaperUrl,
          });
        }
      } catch (error) {
        console.log("An error occurred while getting Whitepaper.");
      }
    };

    const postViewPdfData = async (whitePaperId) => {
      try {
        const formData = new FormData();
        formData.append("id", whitePaperId);

        await axios.post(`${apiUrl}/user/view-pdf`, formData, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });
      } catch (error) {
        console.log("An error occurred while viewing pdf.");
      }
    };

    const getCategoryData = async (categoryId, whitePaperSlug) => {
      try {
        const response = await axios.get(
          `${apiUrl}/category/getsolution-setsbycategory/${categoryId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );

        if (response.data.status) {
          const filteredWhitePaper = response.data.data.solutionSets.filter(
            (whitePaper) => {
              return whitePaper.solutionSet.slug !== whitePaperSlug;
            }
          );
          setCategoryData({
            ...response.data.data.category,
            whitePapers: filteredWhitePaper,
          });
        }
      } catch (error) {
        console.log("An error occurred while getting Category.");
      }
    };

    getWhitePaperData();
  }, []);

  return (
    <div className="md:h-[90vh] w-screen flex flex-col md:flex-row p-2">
      <div className="w-full md:w-1/4 ">
        <div className="border-2  border-slate-200 px-3 py-2 ">
          <p>Suggested Content</p>
        </div>
        <div className="h-screen  w-screen md:w-full md:h-full flex flex-col overflow-scroll">
          {categoryData?.whitePapers?.map((whitepaper) => (
            <div
              className="border-1 border-slate-200 flex cursor-pointer"
              onClick={() =>
                navigate(`/whitepaper/${whitepaper.solutionSet?.slug}`)
              }
            >
              <div
                className="w-[100px] aspect-square bg-cover bg-center"
                style={{
                  backgroundImage: `url("${imgUrl}${whitepaper.solutionSet.imagePath}")`,
                  //   backgroundImage: `url("https://media.gettyimages.com/id/1495819409/photo/digital-mind-brain-artificial-intelligence-concept.jpg?s=612x612&w=gi&k=20&c=PaJl9MVRaZ4nZVhtUPV7g2cuh3UrTqqMXj1dM3B8RHk=")`,
                }}
              ></div>
              <div className="p-4 ">
                <p className="text-gray-600  line-clamp-2 text-wrap">
                  {whitepaper.solutionSet?.description}
                </p>
                <p className=" font-semibold line-clamp-2">
                  <span className="text-slate-400">Case Study by</span>{" "}
                  {whitepaper.solutionSet?.title}
                </p>
              </div>
            </div>
          ))}
          <div className="py-7"></div>
        </div>
        <div></div>
      </div>
      <div className="md:w-3/4 h-screen border-2">
        <embed
          className="w-full h-screen rounded-md"
          //   src={"https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK"}
          src={whitePaperData?.whitepaperUrl}
          type="application/pdf"
        />
      </div>
    </div>
  );
};

export default ViewCaseStudy;
