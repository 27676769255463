import React, { useRef, useState } from "react";
import { Search } from "react-feather";
import { useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [query, setQuery] = useState("");

  const navigate = useNavigate();

  const searchRef = useRef(null); // Reference for detecting outside click

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.trim() === "") {
      return;
    }
  };

  const handleSearch = async () => {
    if (!query) {
      return;
    }
    try {
      navigate("/search?query=" + query);
      setQuery("");
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  return (
    <div className="flex text-white items-center" ref={searchRef}>
      <div className="w-52 mr-2 relative">
        <input
          type="text"
          placeholder="Search..."
          className="bg-transparent border-b border-gray-300 w-full focus:outline-none px-2 py-1"
          value={query}
          onChange={handleChange}
        />
      </div>
      <div onClick={handleSearch} className="cursor-pointer hidden md:block">
        <Search className="h-5 w-5" />
      </div>
    </div>
  );
};

export default SearchBar;
