import React, { useEffect, useState } from "react";
import { Viewer } from "@react-pdf-viewer/core";
import { ChevronRight, Download, Home, Share2 } from "react-feather";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import FeaturedSolution from "../components/FeaturedSolution";
import { useSelector } from "react-redux";
import { apiUrl } from "../utils/constant";
import useBookmark from "../hooks/useBookmark";
import { Slide, ToastContainer } from "react-toastify";

const CaseStudy = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);
  const [whitePaperData, setWhitePaperData] = useState({});
  const [categoryData, setCategoryData] = useState({});

  const location = useLocation();

  const { isSaved, handleBookmarkClick } = useBookmark(
    whitePaperData.id,
    whitePaperData?.isSaved ?? false
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    const getWhitePaperData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user/get-whitepaper/name/${slug}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );

        if (response.data.status) {
          const categoryId = response.data.data.category.id;
          const whitePaper = response.data.data.whitePaper;

          getCategoryData(categoryId, whitePaper.slug);
          setWhitePaperData({
            categoryId: categoryId,
            ...whitePaper,
            isSaved: response.data.data.isSaved,
            whitepaperUrl: response.data.data.whitepaperUrl,
          });
        }
      } catch (error) {
        console.log("An error occurred while getting Whitepaper.");
      }
    };

    const getCategoryData = async (categoryId, whitePaperSlug) => {
      try {
        const response = await axios.get(
          `${apiUrl}/category/getsolution-setsbycategory/${categoryId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );

        if (response.data.status) {
          const filteredWhitePaper = response.data.data.solutionSets.filter(
            (whitePaper) => {
              return whitePaper.solutionSet.slug !== whitePaperSlug;
            }
          );
          setCategoryData({
            ...response.data.data.category,
            whitePapers: filteredWhitePaper,
          });
        }
      } catch (error) {
        console.log("An error occurred while getting Category.");
      }
    };

    getWhitePaperData();
  }, [slug]);

  const downloadPdf = async () => {
    if (!token) {
      navigate("/login", { state: { path: location.pathname } });
      return;
    }

    // Configuration for Axios request
    const config = {
      responseType: "blob", // Important for downloading files
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.get(
        // "https://pdfobject.com/pdf/sample.pdf",
        whitePaperData?.whitepaperUrl,
        config
      );

      // Create a Blob from the response data
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = whitePaperData.name; // Set the file name
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(fileURL);
      downloadPdfData();
    } catch (error) {
      console.error(
        "Error downloading PDF:",
        error.response?.data || error.message
      );
      console.log("Failed to download PDF. Please try again.");
    }
  };

  const downloadPdfData = async () => {
    try {
      const formData = new FormData();
      formData.append("id", slug);

      await axios.post(`${apiUrl}/user/download-pdf`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });
    } catch (error) {
      console.log("An error occurred while downloading pdf.");
    }
  };

  const sharePdf = () => {
    if (!token) {
      navigate("/login", { state: { path: location.pathname } });
      return;
    }
    const whitepaperUrl = window.location.href;

    if (navigator.share) {
      navigator
        .share({
          title: whitePaperData?.title,
          url: whitepaperUrl,
        })
        .then(() => console.log("PDF shared successfully!"))
        .catch((error) => console.error("Error sharing PDF:", error));
    } else {
      alert("Sharing is not supported on this browser.");
    }
  };

  const viewHandler = () => {
    if (!token) {
      navigate("/login", { state: { path: location.pathname } });
    } else {
      navigate(`/view-whitepaper/${slug}`);
    }
  };

  return whitePaperData?.categoryId ? (
    <div>
      <header className="w-full bg-white shadow-lg rounded-lg p-3 px-4">
        <div className="flex items-center gap-2 text-[#4702a2]">
          <Link to="/home">
            <Home className="text-[#4702a2]" size={20} />
          </Link>
          <ChevronRight className="text-[#4702a2]" size={20} />
          <Link
            to={`/category/${whitePaperData?.categorySlug}`}
            className="text-gray-700 text-sm no-underline"
          >
            Category
          </Link>
          <ChevronRight className="text-[#4702a2]" size={20} />
          <span className="text-gray-700 text-sm no-underline hover:cursor-pointer">
            Whitepaper
          </span>
        </div>
      </header>
      <div className="md:w-[90%] items-start flex flex-col-reverse gap-3 lg:flex-row justify-center lg:min-h-screen bg-[#f0f1f2] m-auto p-4">
        <div
          onClick={viewHandler}
          className="hover:bg-[#000] hover:opacity-30 relative cursor-pointer overflow-hidden sm:w-full w-full lg:w-1/3 h-[60vh] lg:h-4/6 rounded-md group order-1 lg:order-3 flex items-center"
        >
          <div className="m-auto w-3/4 lg:w-full h-full mr-3">
            <Viewer
              className="border-2 border-purple-600"
              fileUrl={whitePaperData?.whitepaperUrl}
              initialPage={1}
              defaultScale={0.7}
            />
          </div>
        </div>
        <div className="w-full lg:w-4/6 order-2">
          <div className="w-full flex-wrap flex flex-col">
            <div>
              <h1 className="font-semibold">{whitePaperData?.title}</h1>
            </div>
            <div>
              <p className="text-xl leading-8 line-clamp-5 lg:line-clamp-none">
                {whitePaperData?.description}
              </p>
            </div>
          </div>
          <button
            className="bg-gradient-to-r from-teal-400 to-purple-500 text-white px-6 py-2 rounded"
            onClick={viewHandler}
          >
            {!token ? "Join for Free" : "View Whitepaper"}
          </button>
        </div>
        <div className="flex justify-end lg:flex-col gap-3 order-3 lg:order-1">
          <Download
            onClick={downloadPdf}
            className="text-[#4702a2] cursor-pointer"
            size={30}
          />
          {isSaved ? (
            <p
              onClick={handleBookmarkClick}
              className="text-[#4702a2] cursor-pointer m-0 p-0 text-3xl"
            >
              <i className="ri-bookmark-fill"></i>
            </p>
          ) : (
            <p
              onClick={handleBookmarkClick}
              className="text-[#4702a2] cursor-pointer m-0 p-0 text-3xl"
            >
              <i className="ri-bookmark-line"></i>
            </p>
          )}

          <Share2
            onClick={sharePdf}
            className="text-[#4702a2] cursor-pointer"
            size={30}
          />
        </div>
      </div>
      <FeaturedSolution
        whitePapers={categoryData?.whitePapers}
        categoryData={{
          id: categoryData?.id,
          name: categoryData?.name,
          slug: categoryData?.slug,
        }}
      />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </div>
  ) : null;
};

export default CaseStudy;
