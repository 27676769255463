import React, { useEffect, useState } from "react";
import ProfileSidebar from "../../../components/ProfileSidebar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { apiUrl, imgUrl } from "../../../utils/constant";
import { Link } from "react-router-dom";
import { setUsername, setProfilePicture } from "../../../app/slices/userSlice";
import { User } from "react-feather";
import { toast, ToastContainer, Zoom } from "react-toastify";

export default function ProfileSettings() {
  const profilePicture = useSelector((state) => state.user.profilePicture);

  const [profileData, setProfileData] = useState({});
  const [selectedImage, setSelectedImage] = useState(imgUrl + profilePicture);
  const [fileUpdated, setFileUpdated] = useState(null);
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/newsletter-status`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });
        if (response.data.status) {
          setProfileData(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // File size exceeds 2MB
        setError("File size should not exceed 2MB.");
        setSelectedImage(imgUrl + profilePicture);
        setFileUpdated(null);
        return;
      } else if (!file.type.startsWith("image/")) {
        setError("File is not image");
        setSelectedImage(imgUrl + profilePicture);
        setFileUpdated(null);
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
      setFileUpdated(file);

      setError("");
    } else {
      setSelectedImage(imgUrl + profilePicture);
      setError("");
      setFileUpdated(null);
    }
  };

  const uploadProfile = async (e) => {
    try {
      if (!fileUpdated) {
        return;
      }
      const formData = new FormData();
      formData.append("file", fileUpdated);
      const response = await axios.post(
        `${apiUrl}/user/upload-profilepicture`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        }
      );
      dispatch(
        setProfilePicture({
          profilePicture: response?.data?.data?.profilePicture,
        })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const submithandler = async (e) => {
    e.preventDefault();
    await Promise.all([updateProfile(), uploadProfile()]);
    toast.info("Profile Updated", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Zoom,
    });
  };

  const updateProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("name", profileData.name);
      formData.append("lastName", profileData.lastName);
      formData.append("country", profileData.country);
      formData.append("phone", profileData.phone);
      formData.append("company", profileData.company);
      formData.append("jobTitle", profileData.jobTitle);
      formData.append("email", profileData.email);

      await axios.post(`${apiUrl}/user/update-details`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });
      dispatch(
        setUsername({ username: profileData.name + " " + profileData.lastName })
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <section className="w-full flex">
        <ProfileSidebar />
        <div className=" px-5 pt-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left">Settings</h3>
          <form onSubmit={submithandler} className="py-3 md:w-1/2">
            <div className="flex flex-col md:flex-row gap-3">
              <div className="h-20 md:h-36 w-20 md:w-36 rounded-full bg-heading flex justify-center items-center">
                {selectedImage ? (
                  <div
                    className="profile-setting-img"
                    style={{
                      backgroundImage: `url(${selectedImage})`,
                    }}
                  ></div>
                ) : (
                  <User />
                )}{" "}
              </div>
              <div className="flex flex-col justify-center">
                <h2>Profile Image</h2>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  className=" cursor-pointer w-full sm:w-auto bg-white file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 py-2">
              <div className="flex flex-col gap-2 ">
                <label htmlFor="name">First Name</label>
                <input
                  type="text"
                  className="border py-1 px-2 rounded"
                  placeholder="First Name"
                  name="name"
                  value={profileData?.name}
                  onChange={(e) => {
                    setProfileData({ ...profileData, name: e.target.value });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  className="border py-1 px-2 rounded"
                  placeholder="Last Name"
                  name="lastName"
                  value={profileData?.lastName}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      lastName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label htmlFor="country">Country</label>
                <input
                  type="text"
                  className="border py-1 px-2 rounded"
                  placeholder="Country"
                  name="country"
                  value={profileData?.country}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      country: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="number"
                  className="border py-1 px-2 rounded"
                  placeholder="Phone Number"
                  name="phone"
                  value={profileData?.phone}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label htmlFor="company">Company Name</label>
                <input
                  type="text"
                  className="border py-1 px-2 rounded"
                  placeholder="Company Name"
                  name="company"
                  value={profileData?.company}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      company: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col gap-2 ">
                <label htmlFor="jobTitle">Job Title</label>
                <input
                  type="text"
                  className="border py-1 px-2 rounded"
                  placeholder="Job Title"
                  name="jobTitle"
                  value={profileData?.jobTitle}
                  onChange={(e) => {
                    setProfileData({
                      ...profileData,
                      jobTitle: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <label htmlFor="email">Email ID</label>
              <input
                type="email"
                className="border py-1 px-2 rounded"
                placeholder="Email ID"
                name="email"
                value={profileData?.email}
                onChange={(e) => {
                  setProfileData({
                    ...profileData,
                    email: e.target.value,
                  });
                }}
                readOnly
              />
            </div>
            <Link to="/profile-change-password">
              <p className="mt-3">Change Password</p>
            </Link>
            <button className="text-white w-fit my-2 py-1 px-5 bg-gradient-to-r from-[#3F179B] to-[#5493A4] rounded">
              Save
            </button>
          </form>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
