import { motion } from "framer-motion";
import { fadeIn } from "../utils/variants";

import heroImg from "../assets/About_US_Panda.png";
import iconImg1 from "../assets/img/Asset-2.png";
import iconImg2 from "../assets/img/Asset-3.png";
import iconImg3 from "../assets/img/Asset-4.png";
import iconImg4 from "../assets/img/Asset-5.png";
import heroImg2 from "../assets/img/Asset-6.png";
import aboutUsVideo from "../assets/video/AboutUs.mp4";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const feature = [
  {
    img: iconImg1,
    title: "Curated Whitepapers",
    content:
      "Explore a vast library of whitepapers covering various industries and topics, providing valuable insights and actionable strategies",
  },
  {
    img: iconImg2,
    title: " Expert Insights",
    content:
      "Stay informed with analyses and thought leadership from industry experts and professionals.",
  },
  {
    img: iconImg3,
    title: "Latest Industry Trends",
    content:
      "Access up-to-date reports and market trends to keep your business competitive in an ever-evolving landscape.",
  },
  {
    img: iconImg4,
    title: " Vendor Solutions",
    content:
      "Explore a vast library of whitepapers covering various industries and topics, providing valuable insights and actionable strategies.",
  },
];

const AboutUs = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);

  return (
    <>
      <div className=" relative flex justify-center py-24 font-montserrat bg-gradient-to-r from-[#4702A2] to-[#4F88A2]">
        <video
          src={aboutUsVideo}
          className=" absolute inset-0 w-full h-full object-cover"
          autoPlay
          muted
          loop
          playsInline
        ></video>
        <h1 className="relative text-6xl md:text-8xl text-white font-bold ">
          About Us
        </h1>
      </div>
      <main className="font-montserrat px-5 max-w-[1400px] mx-auto ">
        <motion.section className="grid grid-cols-1 md:grid-cols-2 py-5 gap-3">
          <div className="">
            <h2 className="text-heading text-3xl md:text-5xl font-bold ">
              Discover, Learn, Grow - Welcome to Infeedu
            </h2>
            <div className="bg-gradient-to-r from-[#4702A2] to-[#4F88A2] h-[2px] w-full mb-3"></div>
            <div className="text-xl md:text-2xl">
              <p>
                {" "}
                Infeedu is your go-to platform for staying informed with the
                latest trends, insights, and research in the B2B landscape. We
                provide a comprehensive collection of whitepapers, industry
                reports, and expert analyses to help professionals make
                data-driven decisions and stay ahead in their respective
                industries.
              </p>
              <p>
                With a focus on delivering high-quality content from trusted
                sources, Infeedu ensures that businesses have access to the most
                relevant and up-to-date information. Whether you're looking for
                in-depth research, market trends, or strategic guidance, our
                platform offers a seamless experience tailored to meet your
                needs.
              </p>
            </div>
          </div>
          <div className="flex justify-center">
            <motion.img
              src={heroImg}
              alt="Hero About Us"
              className="max-h-[550px]"
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
            />
          </div>
        </motion.section>
        <motion.section className="py-5">
          <div className="flex justify-center">
            <div className="w-fit">
              <h2
                className="text-heading text-3xl md:text-5xl font-bold "
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                What You'll Find on Infeedu
              </h2>
              <div className="bg-gradient-to-r from-[#4702A2] to-[#4F88A2] h-[2px] w-full mb-3"></div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mt-3">
            {feature.map((data, index) => (
              <motion.div
                key={index}
                className="flex flex-col gap-3 items-center"
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                <div className="flex justify-center p-4 rounded-2xl   shadow-xl w-full">
                  <img src={data.img} alt="Icon" className="" />
                </div>
                <h3 className="text-heading font-bold text-2xl">
                  {data.title}
                </h3>
                <p className="text-center text-xl">{data.content}</p>
              </motion.div>
            ))}
          </div>
        </motion.section>
        <motion.section className="py-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div className="flex justify-center">
              <motion.img
                src={heroImg2}
                alt="Hero"
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              />
            </div>
            <div className="">
              <div className="w-fit">
                <h2 className="text-heading text-3xl md:text-5xl font-bold mt-5">
                  Simplifying Knowledge, Amplifying Growth.
                </h2>
                <div className="bg-gradient-to-r from-[#4702A2] to-[#4F88A2] h-[2px] w-full mb-3"></div>
              </div>
              <p className="text-xl md:text-2xl">
                At Infeedu, our goal is to empower businesses with the knowledge
                they need to succeed. Our platform is designed to eliminate the
                noise and provide a focused, user-friendly experience that makes
                discovering valuable information effortless.
              </p>
              {!token && (
                <button
                  className="py-2 px-4 text-xl text-white bg-gradient-to-r from-[#4702A2] to-[#4F88A2] rounded-xl"
                  onClick={() => navigate(`/login`)}
                >
                  Join Us
                </button>
              )}
            </div>
          </div>
        </motion.section>
      </main>
    </>
  );
};

export default AboutUs;
