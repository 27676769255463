import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export default function OtpVerification() {
  const location = useLocation();
  const { email } = location.state || {};
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const handleInputChange = (value, index) => {
    if (!/^[0-9]*$/.test(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if a digit is entered
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move to the previous input on backspace if the current input is empty
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleSubmit = () => {
    const otpValue = otp.join("");
    if (otpValue.length === 6) {
      console.log("Entered OTP:", otpValue);
      alert("OTP Submitted: " + otpValue);
      // Add your API call to verify OTP here
      setOtp(["", "", "", "", "", ""]);
    } else {
      alert("Please enter a valid 6-digit OTP.");
    }
  };

  return (
    <section className="h-[85vh] w-full containerLayout flex justify-center items-center font-montserrat">
      <div className="w-full md:w-[577px] px-5 py-10 flex flex-col items-center bg-white shadow-lg rounded-xl">
        <h4 className="text-2xl">OTP Verification</h4>
        {email && (
          <p variant="p-text">
            We have sent an OTP to <span className="underline">{email}</span>
          </p>
        )}

        <div className="flex gap-2 my-5">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleInputChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-12 h-12 text-center text-xl bg-gray-100 border border-gray-300 rounded-lg focus:ring-2 focus:ring-heading"
            />
          ))}
        </div>

        <button
          className="mt-5 bg-heading text-white text-sm px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          Verify OTP
        </button>
        <button className="my-2 underline text-xs text-heading">
          Resent Code
        </button>
      </div>
    </section>
  );
}
