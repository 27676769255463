import { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Home } from "react-feather";
import { Link, useLocation } from "react-router-dom";
import { FeatureCard } from "../components/FeatureCard";
import axios from "axios";
import { apiUrl } from "../utils/constant";
import Loader from "../components/Loader";

export default function Search() {
  const [searchWhitepapers, setSearchWhitepapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const location = useLocation();
  const query = new URLSearchParams(location.search).get("query");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("name", query);

        const response = await axios.post(
          `${apiUrl}/user/homepage-search`,
          formData
        );

        setSearchWhitepapers(response.data.data.whitepapers);
        setLoading(false);
      } catch (error) {
        console.log("Error", error.message);
      }
    };
    fetchData();
  }, [query]);

  const cardsPerPage = 8;

  const totalPages = Math.ceil(searchWhitepapers.length / cardsPerPage);

  const handlePrevious = () => {
    setCurrentPage((prev) => (prev > 0 ? prev - 1 : totalPages - 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => (prev < totalPages - 1 ? prev + 1 : 0));
  };

  const getCurrentPageCards = () => {
    const startIndex = currentPage * cardsPerPage;
    return searchWhitepapers.slice(startIndex, startIndex + cardsPerPage);
  };
  return (
    <>
      <header className="bg-white p-3 rounded-lg shadow-lg w-full px-4">
        <div className="flex text-[#4702a2] gap-2 items-center max-w-[1400px] md:px-6 mx-auto">
          <Link to="/home">
            <Home className="text-gray-700" size={20} />
          </Link>
          <ChevronRight className="text-gray-700" size={20} />
          <Link to="/search" className="text-gray-700 text-sm no-underline">
            SEARCH
          </Link>
          <ChevronRight className="text-gray-700" size={20} />
        </div>
      </header>
      <main className="font-montserrat max-w-[1400px] md:px-6 mx-auto">
        <h3 className="bg-heading p-4 rounded text-white w-fit font-normal my-4">
          Search results for{" "}
          <strong>
            {query} : {!loading ? searchWhitepapers.length : 0}
          </strong>
        </h3>
        {!loading ? (
          <div className="lg:px-28 mt-8 px-4 relative">
            <div className="grid grid-cols-1 gap-2 lg:gap-4 lg:grid-cols-4 md:gap-3 md:grid-cols-2">
              {getCurrentPageCards()?.map((featureCard, index) => (
                <FeatureCard
                  key={index}
                  featureCard={{
                    ...featureCard.solutionSet,
                    isSavedByUser: featureCard.isSaved,
                    imgSrc: featureCard.solutionSet.imagePath,
                    category: featureCard.solutionSet.categoryName,
                  }}
                />
              ))}
            </div>
            {/* Navigation Controls */}
            <div className="flex justify-center gap-4 items-center mt-8">
              <button
                onClick={handlePrevious}
                className="bg-white p-2 rounded-full shadow-lg hover:shadow-xl"
              >
                <ChevronLeft size={24} />
              </button>

              {/* Pagination Dots */}
              <div className="flex gap-2 items-center">
                {Array.from({ length: totalPages }).map((_, index) => (
                  <div
                    key={index}
                    className={`h-3 w-3 rounded-full ${
                      currentPage === index ? "bg-purple-800" : "bg-gray-300"
                    }`}
                  />
                ))}
              </div>

              <button
                onClick={handleNext}
                className="bg-white p-2 rounded-full shadow-lg hover:shadow-xl"
              >
                <ChevronRight size={24} />
              </button>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </main>
    </>
  );
}
