import React from "react";
import { Facebook, Linkedin, Instagram } from "react-feather";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-gray-800 to-black py-8 text-white relative px-2 mt-6 ">
      <div
        className="absolute inset-0 w-full bg-cover bg-center opacity-20 h-[60%]"
        style={{ backgroundImage: `url(${require("../assets/img/Img5.jpg")})` }}
      ></div>

      <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-15 px-6 relative z-10">
        {/* 1st Section */}
        <div className="text-left flex flex-col items-start space-y-4">
          <img
            src={require("../assets/infeedu.png")}
            alt="Logo"
            className="h-10 w-auto"
          />
          <span>Follow us on</span>
          <div className="flex space-x-4">
            <Link
              to="https://www.facebook.com/profile.php?id=61569977827608"
              className="bg-white text-black rounded-full p-2"
            >
              <Facebook className="w-6 h-6 " />
            </Link>
            <Link
              to="https://www.instagram.com/infeedu/"
              className="bg-white text-black rounded-full p-2"
            >
              <Instagram className="w-6 h-6" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/infinite-b2b/"
              className="bg-white text-black rounded-full p-2"
            >
              <Linkedin className="w-6 h-6" />
            </Link>
          </div>
        </div>

        {/* 2nd Section */}
        <div className="text-left my-3 md:my-0 flex flex-col justify-center">
          <p>
            B2B solutions from all vendors in all industries aggregated and
            curated in an easy-to-use discovery platform
          </p>
        </div>

        {/* 3rd Section */}
        <div className="text-left">
          <ul className="space-y-2 pl-0 md:pl-[1rem]">
            <li>
              <Link
                className="no-underline text-white  cursor-pointer hover:underline underline-offset-2 ease-in-out duration-400"
                to="/whitepapers-set"
              >
                WhitePapers Set
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white cursor-pointer hover:underline underline-offset-2 ease-in-out duration-400"
                to="/newsletters"
              >
                Blogs
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white cursor-pointer hover:underline underline-offset-2 ease-in-out duration-400"
                to="/newsletters"
              >
                Newsletter
              </Link>
            </li>
            <li>
              <Link
                className="no-underline text-white cursor-pointer hover:underline underline-offset-2 ease-in-out duration-400"
                to="/aboutus"
              >
                About us
              </Link>
            </li>
            <li>
              <a
                className="no-underline text-white cursor-pointer hover:underline underline-offset-2 ease-in-out duration-400"
                href="mailto:info@infeedu.com"
              >
                Contact us
              </a>
            </li>
          </ul>
        </div>

        {/* 4th Section */}
        <div className="flex flex-col items-start space-y-4">
          <span>Publish your Whitepaper</span>
          <button
            className="bg-[#4702a2] text-white px-6 py-2 rounded hover:bg-[rgb(147,101,203)] transition duration-300 font-montserrat"
            onClick={() =>
              (window.location.href =
                "https://dashboard.infeedu.com/vendor/login")
            }
          >
            Vendor Center
          </button>
        </div>
      </div>

      {/* Divider Line */}
      <div className="border-t border-gray-600 mt-8"></div>

      {/* Bottom Section */}
      <div className="container mx-auto text-center mt-4 mb-0">
        <span className="text-gray-400 cursor-default">
          &copy;2025 Infeedu. All Rights Reserved | &nbsp;
          <Link
            className="no-underline text-gray-400 cursor-pointer hover:underline underline-offset-2 ease-in-out duration-300"
            to="/gdpr-policy"
          >
            GDPR Compliance Policy
          </Link>
          &nbsp; | &nbsp;
          <Link
            className="no-underline text-gray-400 cursor-pointer hover:underline underline-offset-2 ease-in-out duration-300"
            to="/privacy-policy"
          >
            Legal
          </Link>
          &nbsp; | &nbsp;
          <Link
            className="no-underline text-gray-400 cursor-pointer hover:underline underline-offset-2 ease-in-out duration-300"
            to="/terms-condition"
          >
            Do not sell my info
          </Link>
          &nbsp;
        </span>
      </div>
    </footer>
  );
};

export default Footer;
