import React, { useEffect, useState } from "react";
import axios from "axios";

import Statistics from "./StackSection";
import CircularDivs from "./CategorySection";
import TrustedVender from "./TrustedVender";
import Curated from "./Curated";
import {
  MostDowloaded,
  Section2home,
  TrendingWhitepapers,
} from "./Section2home";
import HeroSection from "./HeroSection";
import { apiUrl } from "../utils/constant";
import { useSelector } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import SubHeroSection from "./SubHeroSection";

const HomePage = () => {
  const [homeScreenData, sethomeScreenData] = useState({
    poweringYourBusiness: [],
    curatedWhitePapers: [],
    topicsThatMatterYou: [],
    mostDownloaded: [],
    trendingWhitePapers: [],
  });

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user/solution-sets-homepage`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );
        if (response.data.status) {
          sethomeScreenData({
            ...response.data.data,
          });
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <HeroSection />
      <SubHeroSection />
      <Section2home
        featuredSolutionData={homeScreenData?.poweringYourBusiness}
      />
      <TrendingWhitepapers
        trendingWhitePapers={homeScreenData?.trendingWhitePapers}
      />
      <MostDowloaded mostDownloaded={homeScreenData?.mostDownloaded} />

      <Curated
        curatedWhitePapers={homeScreenData?.curatedWhitePapers}
        mostDownloaded={homeScreenData?.mostDownloaded}
      />
      <Statistics statsCount={homeScreenData?.STATISTICS} />
      <CircularDivs topicsThatMatterYou={homeScreenData?.topicsThatMatterYou} />
      <TrustedVender />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default HomePage;
