import React from "react";
import { Bell, Bookmark } from "react-feather";
import { useNavigate } from "react-router-dom";
import useBookmark from "../hooks/useBookmark";
import { imgUrl } from "../utils/constant";

const Card = ({ featureCard, categaryTitle }) => {
  const { isSaved, handleBookmarkClick } = useBookmark(
    featureCard.solutionSet.id,
    featureCard?.isSaved ?? false
  );

  const navigate = useNavigate();

  return (
    <div
      key={featureCard.solutionSet.title}
      className="w-full sm:w-1/2 lg:w-1/4 px-2 my-3 font-montserrat"
    >
      <div className="bg-white shadow-lg rounded-lg ">
        <div
          className="cursor-pointer"
          onClick={() =>
            navigate(`/whitepaper/${featureCard.solutionSet.slug}`)
          }
        >
          <div
            className="w-full h-60 bg-contain bg-no-repeat bg-center"
            style={{
              backgroundImage: `url("${imgUrl}${featureCard.solutionSet.imagePath}")`,
            }}
          ></div>
          <div className="p-3">
            <h3 className="text-xl font-semibold mb-2  text-[#4702a2] font-montserrat inline-block border-b-2 border-[#4702a2] pb-1 w-full h-14 cursor-pointer line-clamp-2">
              {featureCard.solutionSet.title}
            </h3>
            <p className="text-black line-clamp-2 font-montserrat h-12 my-0">
              {featureCard.solutionSet.description}
            </p>
          </div>
        </div>
        <div className="flex justify-between items-center px-4 py-2 bg-[#042C54] text-white rounded-t-2xl">
          <h4 className="text-lg font-semibold line-clamp-1 font-montserrat m-0">
            {categaryTitle}
          </h4>
          {isSaved ? (
            <p
              className="text-white cursor-pointer text-lg m-0 p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick();
              }}
            >
              <i className="ri-bookmark-fill"></i>
            </p>
          ) : (
            <p
              className="text-white cursor-pointer text-lg m-0 p-0"
              onClick={(e) => {
                e.stopPropagation();
                handleBookmarkClick();
              }}
            >
              <i className="ri-bookmark-line"></i>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
