import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight, Home, Search } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import useSubscribe from "../../../hooks/useSubscribe";
import { Slide, ToastContainer } from "react-toastify";

const Whitepapers = () => {
  const [filtercategoryData, setFilterCategoryData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [letterFilter, setLetterFilter] = useState("All");
  const [page, setPage] = useState(1);
  const token = useSelector((state) => state.user.token);

  //Pagination
  const itemsPerPage = 20;
  const totalPages = Math.ceil(filtercategoryData.length / itemsPerPage);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/category`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });
        if (response.data.status) {
          setCategoryData(response.data.data);
          setFilterCategoryData(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (value) => {
    const filteredData = categoryData.filter((category) =>
      category.name.toLowerCase().includes(value.toLowerCase())
    );
    setPage(1);
    setFilterCategoryData(filteredData);
  };

  const updateFilter = (value) => {
    setLetterFilter(value);
    if (value === "All") {
      setFilterCategoryData(categoryData);
    } else {
      const filteredData = categoryData.filter(
        (category) =>
          category.name.toLowerCase().charAt(0) === value.toLowerCase()
      );

      setPage(1);
      setFilterCategoryData(filteredData);
    }
  };

  const nextPageHandler = () => {
    if (page < filtercategoryData.length / itemsPerPage) {
      setPage(page + 1);
    }
  };
  const prevPageHandler = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <>
      <header className="bg-white p-3 rounded-lg shadow-lg w-full px-4">
        <div className="flex text-[#4702a2] gap-2 items-center max-w-[1400px] md:px-6 mx-auto">
          <Link to="/home">
            <Home className="text-gray-700" size={20} />
          </Link>
          <ChevronRight className="text-gray-700" size={20} />
          <Link
            to="/whitepapers"
            className="text-gray-700 text-sm no-underline"
          >
            WHITEPAPERS
          </Link>
          <ChevronRight className="text-gray-700" size={20} />
        </div>
      </header>

      <div className="flex flex-col p-6 items-center lg:flex-row lg:justify-start lg:space-y-0 max-w-[1400px] mx-auto space-y-4">
        <div className="flex bg-[#4702a2] p-3 rounded-lg text-white w-full items-center lg:flex-grow-0 lg:w-auto">
          <span className="font-semibold mr-4">FILTER:</span>
          <div className="flex flex-wrap gap-1">
            <button
              className={`p-1 rounded-lg text-sm
                  ${
                    letterFilter === "All"
                      ? "font-bold underline text-green-500"
                      : "text-white"
                  } 
                  hover:underline transition-all duration-300 ease-in-out`}
              onClick={() => updateFilter("All")}
            >
              All
            </button>
            {Array.from("ABCDEFGHIJKLMNOPQRSTUVWXYZ")?.map((letter) => (
              <button
                key={letter}
                className={`p-1 rounded-lg text-sm
                  ${
                    letterFilter === letter
                      ? "font-bold underline text-green-500"
                      : "text-white"
                  } 
                  hover:underline transition-all duration-300 ease-in-out`}
                onClick={() => updateFilter(letter)}
              >
                {letter}
              </button>
            ))}
          </div>
        </div>

        <div className="flex border-b-2 p-2 w-full items-center lg:ml-16 lg:w-1/3">
          <input
            type="text"
            placeholder="Search..."
            className="flex-1 text-gray-700 outline-none placeholder-gray-500"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <Search className="mr-2" size={20} />
        </div>
      </div>

      <div className="grid grid-cols-1 m-2 p-2 gap-6 lg:grid-cols-4 max-w-[1400px] mt-4 mx-auto place-items-center sm:grid-cols-2">
        {filtercategoryData?.length ? (
          <>
            {filtercategoryData
              .slice(page * itemsPerPage - itemsPerPage, page * itemsPerPage)
              ?.map((category, index) => (
                <WhitepaperCard category={category} index={index} />
              ))}
          </>
        ) : (
          <h2 className="text-center">No records found ...</h2>
        )}
      </div>

      {filtercategoryData.length > itemsPerPage && (
        <div className="flex justify-center gap-3">
          <button
            className={`${
              page === 1 && "text-gray-500"
            } p-2 h-10 rounded-full bg-white shadow-lg hover:shadow-xl`}
            onClick={prevPageHandler}
          >
            <ChevronLeft size={24} />
          </button>
          <p className="text-heading text-lg font-bold mt-1">
            {page}/{totalPages}
          </p>
          <button
            onClick={nextPageHandler}
            className={`${
              page === filtercategoryData.length / 20 && "text-gray-500"
            } p-2 h-10 rounded-full bg-white shadow-lg hover:shadow-xl`}
          >
            <ChevronRight size={24} />
          </button>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
};

export default Whitepapers;

const WhitepaperCard = ({ category, index }) => {
  const { isSubscribe, handleSubscribeClick } = useSubscribe(
    category.id,
    category.isSubscribe
  );
  const [imgSrc, setImgSrc] = useState(category?.iconPath);

  useEffect(() => {
    if (category.iconPath) {
      setImgSrc(category.iconPath);
    }
  }, [category]);

  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate(`/category/${category.slug}`);
  };

  return (
    <div
      key={category?.id}
      className="border-[1px] rounded-2xl shadow-xl w-72 group overflow-hidden relative"
      onClick={handleImageClick}
    >
      <div className="overflow-hidden">
        <img
          src={imgSrc}
          alt={category?.name}
          onError={() => {
            setImgSrc("https://i.imgur.com/hfM1J8s.png");
          }}
          className="h-40 w-full cursor-pointer duration-500 ease-in-out group-hover:scale-110 object-cover"
        />
      </div>

      <p className="flex h-[38px] text-[#3d1e87] text-[17px] cursor-pointer duration-300 ease-in-out font-bold font-montserrat leading-5 line-clamp-2 mb-0 mt-2 px-3">
        {category?.name}
      </p>
      <div className="flex justify-between items-center pb-1 px-2">
        <h2 className="m-0 text-[12px] text-gray-400 px-2">
          {category?.whitePaperCount || 0} WHITEPAPERS
        </h2>
        {isSubscribe ? (
          <p
            className="m-0 p-0 text-[20px] text-gray-400 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleSubscribeClick();
            }}
            title="Unsubscribe"
          >
            <i className="ri-notification-off-line"></i>
          </p>
        ) : (
          <p
            className="m-0 p-0 text-[20px] text-gray-400 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleSubscribeClick();
            }}
            title="Subscribe"
          >
            <i className="ri-notification-4-line"></i>
          </p>
        )}
      </div>
    </div>
  );
};
