import React from "react";
import bgHero from "../assets/img/background_hero_2.png";
import imgCard1 from "../assets/img/ImageCard1.png";
import imgCard2 from "../assets/img/ImageCard2.png";
import { motion } from "framer-motion";
import { fadeIn } from "../utils/variants";

const cardData = [
  {
    title: "INFEEDU B2B",
    subHeading: "Unlocking your Business Potential",
    description: "Welcome to INFEEDU B2B, where we convert intent into action.",
    img: imgCard1,
    learnMoreLink: "https://infeedub2b.com",
  },
  {
    title: "AI INFEEDU",
    subHeading: "Identify, Attract, Convert",
    description:
      "AI-driven platform to ensure you get quality leads ready to convert.",
    img: imgCard2,
    learnMoreLink: "https://ai.infeedu.com",
  },
];

export default function SubHeroSection() {
  return (
    <section
      className="md:h-screen font-montserrat"
      style={{
        background: `url(${bgHero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-[1300px] h-full mx-auto flex flex-col md:flex-row items-center gap-10 text-white py-5 px-5 sm:px-2 md:px-0">
        {cardData.map((card, index) => (
          <motion.div
            variants={fadeIn("up", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="w-full  rounded-3xl p-[12px] flex flex-col justify-between bg-gradient-to-r from-[#131623] to-[#4A4E69] "
            key={index}
          >
            <div className="flex justify-between items-center py-2">
              <h3 className="text-3xl md:text-5xl font-bold">{card.title}</h3>
              <button
                className="text-xs md:text-base cursor-pointer m-0 text-white"
                onClick={() => window.open(card.learnMoreLink)}
              >
                Learn more ➤
              </button>
              {/* <a
                href={card.learnMoreLink}
                className="text-xs md:text-base cursor-pointer m-0 text-white"
              >
                Learn more ➤
              </a> */}
            </div>
            <div className="flex flex-col items-center py-3">
              <h4 className="text-2xl md:text-3xl text-center">
                {card.subHeading}
              </h4>
              <p className="text-center w-2/3 md:w-1/2">{card.description}</p>
            </div>
            <img
              src={card.img}
              alt="Card"
              className="rounded-xl max-h-[315px]"
            />
          </motion.div>
        ))}
      </div>
    </section>
  );
}
