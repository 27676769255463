import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";

const BlogView = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);

  const [blog, setBlog] = useState({});

  useEffect(() => {
    const getBlogData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/blogs/get-blog/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });

        if (response.data.status) {
          setBlog(response.data.data);
        }
      } catch (error) {
        console.log("An error occurred while getting Blog Data.");
      }
    };

    getBlogData();
  }, [id]);

  return (
    <div className="flex bg-gray-100 p-4 min-h-screen">
      <div className="flex flex-col bg-white p-6 rounded-lg shadow-md w-full gap-4 max-w-[1400px]">
        <h2 className="text-3xl text-center font-semibold py-2">
          {blog.blogName}
        </h2>
        <img
          src={blog.blogImage}
          alt={blog.blogName}
          className="object-fit-contain"
        />
        <div dangerouslySetInnerHTML={{ __html: blog.blogContent }} />
      </div>
    </div>
  );
};

export default BlogView;
