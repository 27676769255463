import React from "react";
// import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { fadeIn } from "../utils/variants";
import gif from "../assets/img/panda_movement.gif";

const HeroSection = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.token);

  const getStartedHandler = () => {
    if (token) {
      navigate("/whitepapers-set");
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      {/* Add Header component here */}

      <div className="relative flex h-screen">
        <div className="w-full h-full flex justify-center items-center">
          <video
            src={require("../assets/assetsFromyash/new.mp4")}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover"
          />
        </div>

        <div className="absolute top-0 inset-0">
          <img
            src={require("../assets/img/HeroBg.png")}
            alt="Hero Background"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="absolute bottom-5 right-5">
          <img src={gif} alt="Gif of Infinity B2B" className="w-24 md:w-64" />
        </div>

        <motion.div
          variants={fadeIn("right", 0.2)}
          initial="hidden"
          whileInView={"show"}
          viewport={{ once: true, amount: 0.7 }}
          className="absolute inset-0 flex flex-col justify-center items-start text-left p-8 lg:p-20 mt-36"
        >
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-0 leading-none font-montserrat">
            Infinite Possibilities,
          </h1>
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-0 leading-none font-montserrat">
            Limitless Growth Explore
          </h1>
          <h1 className="text-white text-4xl md:text-6xl font-bold mb-0 leading-none font-montserrat">
            White-papers that Matter
          </h1>
          <div className="h-[1px] bg-white w-[25%] md:w-[10%] mt-1"></div>

          <p className="text-white text-base md:text-lg font-montserrat">
            Access 600+ industries, connect with <br />
            thousands of vendors, and uncover insights <br />
            that propel your business forward
          </p>

          <div className="flex space-x-4">
            <button
              className="bg-[#4702a2] text-white px-6 py-2 rounded hover:bg-[rgb(147,101,203)] transition duration-300 font-montserrat"
              onClick={getStartedHandler}
            >
              GET STARTED
            </button>
            <button
              className="text-white px-6 py-2 rounded border border-white hover:text-black transition duration-300 font-montserrat"
              onClick={() => navigate("/aboutus")}
            >
              LEARN MORE
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default HeroSection;

// import React from 'react';
// import Header from './Header';

// const HeroSection = () => {
//   return (
//     <div className="relative flex h-screen">

//       <div className="w-full h-full flex justify-center items-center">

//         <video
//           src={require('../assets/assetsFromyash/new.mp4')}
//           autoPlay
//           loop
//           muted
//           className="w-full h-full object-cover"
//         />
//       </div>

//       <div className="absolute inset-0">

//         <img
//           src={require('../assets/assetsFromyash/HeroBg1.png')}
//           alt="Hero Background"
//           className="w-full h-full object-cover"
//         />
//       </div>

//       <div className="absolute inset-0 flex flex-col justify-center items-start text-left p-20 mt-36">
//         <h1 className="text-white text-6xl font-bold mb-0 leading-none font-montserrat">
//           SOLUTIONS FOR
//         </h1>
//         <h1 className="text-white text-6xl font-bold mb-0 leading-none font-montserrat">
//           EVERY BUSINESS
//         </h1>
//         <h1 className="text-white text-6xl font-bold mb-0 leading-none font-montserrat">
//           CHALLENGE
//         </h1>
//         <div className="h-[1px] bg-white w-[10%] mt-1"></div>

//         <p className="text-white font-montserrat">
//           Access 600+ industries, connect with <br />
//           thousands of vendors, and uncover insights <br />
//           that propel your business forward
//         </p>

//         <div className="flex space-x-4">
//           <button
//             className="bg-[#4702a2] text-white px-6 py-2 rounded hover:bg-[rgb(147,101,203)] transition duration-300 font-montserrat"
//             // onClick={() => navigate('/login')}
//           >
//             GET STARTED
//           </button>
//           <button className="text-white px-6 py-2 rounded border border-white hover:text-black transition duration-300 font-montserrat">
//             LEARN MORE
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;
