import React, { useRef, useState } from "react";
import { Lock, Mail } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrl } from "../utils/constant";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setUser } from "../app/slices/userSlice";

export default function ResetPassword() {
  const location = useLocation();
  const email = new URLSearchParams(location.search).get("email");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isVisiblePassword, setIsVisiblePassword] = useState(false);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [password, setPassword] = useState("");
  const inputRefs = useRef([]);

  const handleInputChange = (value, index) => {
    if (!/^[0-9]*$/.test(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input if a digit is entered
    if (value && index < otp.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      // Move to the previous input on backspace if the current input is empty
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePasswordChange = (val) => {
    setPassword(val);
  };

  const handleResetPassword = async () => {
    const otpVal = otp.join("");

    if (!otpVal || otpVal.length !== 6) {
      alert("Please enter valid otp");
    } else if (!password) {
      alert("Please enter password");
    } else {
      try {
        const formData = new FormData();
        formData.append("email", email);
        formData.append("otp", otpVal);
        formData.append("newPassword", password);

        const response = await axios.post(
          `${apiUrl}/user/verify-otp-and-reset-password`,
          formData
        );

        if (response.data.status) {
          dispatch(setUser(response.data.data));
          navigate("/home");
        }
      } catch (error) {
        console.error("Error:", error);
        alert(error.response.data.message);
      }
    }
  };

  return (
    <section className="md:h-screen py-20 bg-[#F3F4F6] flex justify-center items-center font-montserrat">
      <div className="w-full p-8 max-w-[448px] bg-white rounded-xl shadow">
        <h4 className="text-2xl font-bold mb-6 text-center">
          Reset Password ?
        </h4>
        <p className="text-[14px] text-gray-700 text-center">
          Please enter 6 digit code, Than create and confirm new password.
        </p>
        <div className="flex justify-center gap-2 my-3">
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleInputChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className="w-12 h-12 text-center text-xl bg-gray-100 border border-gray-300 rounded-lg focus:ring-2 focus:ring-heading"
            />
          ))}
        </div>
        <div className="w-full mt-4 mb-2 p-2 border rounded flex items-center">
          <Lock size={18} className="mr-2 text-gray-600" />
          <input
            name="password"
            type={isVisiblePassword ? "text" : "password"}
            placeholder="Reset Password"
            className="w-full outline-none"
            value={password}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />

          <div>
            {isVisiblePassword ? (
              <span
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className=" cursor-pointer"
              >
                <i className="ri-eye-line"></i>
              </span>
            ) : (
              <span
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className=" cursor-pointer"
              >
                <i className="ri-eye-off-line"></i>
              </span>
            )}
          </div>
        </div>
        {/* <div className="w-full mb-2 p-2 border rounded flex items-center">
          <Lock size={18} className="mr-2 text-gray-600" />
          <input
            name="password"
            type={isVisiblePassword ? "text" : "password"}
            placeholder="Confirm Password"
            className="w-full outline-none"
            value={passwordObj.password2}
            handleChange={(e) => handlePasswordChange(e.target.value, 2)}
          />

          <div>
            {isVisiblePassword ? (
              <span
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className=" cursor-pointer"
              >
                <i className="ri-eye-line"></i>
              </span>
            ) : (
              <span
                onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                className=" cursor-pointer"
              >
                <i className="ri-eye-off-line"></i>
              </span>
            )}
          </div>
        </div> */}
        <button
          className="w-full my-2 py-2 bg-gradient-to-r from-teal-400 to-purple-500 text-white rounded-full"
          onClick={handleResetPassword}
        >
          Reset Password
        </button>
      </div>
    </section>
  );
}
