import React, { useEffect, useState } from "react";
import useSubscribe from "../../../hooks/useSubscribe";
import { useNavigate } from "react-router-dom";
import ProfileSidebar from "../../../components/ProfileSidebar";
import { ChevronLeft, ChevronRight } from "react-feather";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";

export default function SubscribedCategory() {
  const [filtercategoryData, setFilterCategoryData] = useState([]);
  const [page, setPage] = useState(1);
  const token = useSelector((state) => state.user.token);

  //Pagination
  const itemsPerPage = 20;
  const totalPages = Math.ceil(filtercategoryData.length / itemsPerPage);

  const nextPageHandler = () => {
    if (page < filtercategoryData.length / 20) {
      setPage(page + 1);
    }
  };
  const prevPageHandler = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/user/getall-favortie-category`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );
        if (response.data.status) {
          setFilterCategoryData(response.data.data);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="w-full flex">
        <ProfileSidebar />
        <div className=" p-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left ">Subscribed Category</h3>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-3 m-2 mt-4 p-2 place-items-center">
            {filtercategoryData?.length ? (
              <>
                {filtercategoryData
                  .slice(page * 20 - 20, page * 20)
                  ?.map((category, index) => (
                    <WhitepaperCard category={category} index={index} />
                  ))}
              </>
            ) : (
              <h2 className="text-center">No records found ...</h2>
            )}
          </div>
          {filtercategoryData.length > 20 && (
            <div className="flex justify-center gap-3">
              <button
                className={`${
                  page === 1 && "text-gray-500"
                } p-2 h-10 rounded-full bg-white shadow-lg hover:shadow-xl`}
                onClick={prevPageHandler}
              >
                <ChevronLeft size={24} />
              </button>
              <p className="mt-1 text-lg font-bold text-heading">
                {page}/{totalPages}
              </p>
              <button
                onClick={nextPageHandler}
                className={`${
                  page === filtercategoryData.length / 20 && "text-gray-500"
                } p-2 h-10 rounded-full bg-white shadow-lg hover:shadow-xl`}
              >
                <ChevronRight size={24} />
              </button>
            </div>
          )}
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  );
}

const WhitepaperCard = ({ category, index }) => {
  const { isSubscribe, handleSubscribeClick } = useSubscribe(
    category?.id,
    category?.isSubscribe
  );
  const [imgSrc, setImgSrc] = useState(category?.iconPath);

  useEffect(() => {
    if (category?.iconPath) {
      setImgSrc(category?.iconPath);
    }
  }, [category]);

  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate(`/category/${category?.slug}`);
  };

  return (
    <div
      key={category?.id}
      className="relative w-72  rounded-2xl shadow-xl border-[1px] overflow-hidden group"
      onClick={handleImageClick}
    >
      <div className="overflow-hidden">
        <img
          src={imgSrc}
          alt={category?.name}
          onError={() => {
            setImgSrc("https://i.imgur.com/hfM1J8s.png");
          }}
          className="cursor-pointer w-full h-40 object-cover group-hover:scale-110 ease-in-out duration-500"
        />
      </div>

      <p className="font-montserrat text-[#3d1e87] cursor-pointer flex text-[17px] h-[38px] font-bold line-clamp-2 leading-5 duration-300 ease-in-out px-3 mt-2 mb-0">
        {category?.name}
      </p>
      <div className="flex justify-between items-center pb-1 px-2">
        <h2 className="text-[12px] text-gray-400 m-0 px-2">
          {category?.whitePaperCount || 0} WHITEPAPERS
        </h2>
        {isSubscribe ? (
          <p
            className="text-gray-400 cursor-pointer text-[20px] m-0 p-0"
            onClick={(e) => {
              e.stopPropagation();
              handleSubscribeClick();
            }}
            title="Unsubscribe"
          >
            <i className="ri-notification-off-line"></i>
          </p>
        ) : (
          <p
            className="text-gray-400 cursor-pointer text-[20px] m-0 p-0"
            onClick={(e) => {
              e.stopPropagation();
              handleSubscribeClick();
            }}
            title="Subscribe"
          >
            <i className="ri-notification-4-line"></i>
          </p>
        )}
      </div>
    </div>
  );
};
