import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";
import { fadeIn } from "../utils/variants";

const topics = [
  {
    // name: "ARTIFICIAL INTELLIGENCE",
    className:
      "lg:top-[22%] lg:left-[12%] md:top-[15%] md:left-[10%] top-[10%] left-[5%]",
    delay: 0.2,
  },
  {
    // name: "Big Data",
    className:
      "lg:top-[33%] lg:left-[8%] md:top-[25%] md:left-[6%] top-[20%] left-[3%]",
    delay: 0.3,
  },
  {
    // name: "CUSTOMER EXPERIENCE",
    className:
      "lg:top-[45%] lg:left-[5%] md:top-[40%] md:left-[4%] top-[35%] left-[2%]",
    delay: 0.4,
  },
  {
    // name: "ROBOTIC PROCESS AUTOMATION",
    className:
      "lg:bottom-[38%] lg:left-[8%] md:bottom-[35%] md:left-[6%] bottom-[30%] left-[4%]",
    delay: 0.5,
  },
  {
    // name: "AR & VR",
    className:
      "lg:bottom-[26%] lg:left-[15%] md:bottom-[25%] md:left-[12%] bottom-[20%] left-[8%]",
    delay: 0.6,
  },
  {
    // name: "SERVER VIRTUALIZATION",
    className:
      "lg:bottom-[15%] lg:left-[20%] md:bottom-[15%] md:left-[16%] bottom-[12%] left-[12%]",
    delay: 0.7,
  },
  {
    // name: "HEALTHCARE",
    className:
      "lg:top-[33%] lg:left-[40%] md:top-[30%] md:left-[43%] top-[28%] left-[40%] -translate-x-1/2",
    delay: 0.8,
  },
  {
    // name: "DEVOPS",
    className:
      "lg:top-[22%] lg:right-[28%] md:top-[12%] md:right-[28%] top-[8%] right-[25%]",
    delay: 0.9,
  },
  {
    // name: "CLOUD SECURITY",
    className:
      "lg:top-[33%] lg:right-[10%] md:top-[20%] md:right-[10%] top-[15%] right-[8%]",
    delay: 1.0,
  },
  {
    // name: "BLOCKCHAIN",
    className:
      "lg:top-[50%] lg:right-[8%] md:top-[35%] md:right-[6%] top-[30%] right-[4%]",
    delay: 1.1,
  },
  {
    // name: "ABM",
    className:
      "lg:top-[45%] lg:right-[32%] md:top-[45%] md:right-[18%] top-[40%] right-[15%]",
    delay: 1.2,
  },
  {
    // name: "IOT",
    className:
      "lg:bottom-[38%] lg:right-[29%] md:bottom-[35%] md:right-[28%] bottom-[30%] right-[25%]",
    delay: 1.3,
  },
  {
    // name: "CLOUD SAAS",
    className:
      "lg:bottom-[30%] lg:right-[8%] md:bottom-[25%] md:right-[10%] bottom-[20%] right-[8%]",
    delay: 1.4,
  },
  {
    // name: "CUSTOMER EXPERIENCE",
    className:
      "lg:bottom-[20%] lg:right-[22%] md:bottom-[15%] md:right-[13%] bottom-[12%] right-[10%]",
    delay: 1.5,
  },
];

const CategorySection = ({ topicsThatMatterYou }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <>
      {/* <div className="w-full bg-white mx-auto px-4 md:px-6 lg:px-8 overflow-hidden hidden md:block"> */}
      <div className="bg-white overflow-hidden md:block">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={containerVariants}
          className="mx-auto"
          // className="max-w-7xl mx-auto"
        >
          <div>
            <motion.h1
              variants={itemVariants}
              className="pt-4 text-[#4702a2] text-3xl md:text-5xl lg:text-4xl font-bold mx-2 text-center font-montserrat"
            >
              TOPICS THAT MATTER TO YOU
            </motion.h1>
            <motion.p className="flex justify-center text-center items-center text-lg md:text-2xl lg:text-lg leading-relaxed tracking-wide mx-2 font-montserrat">
              Save, share and organize solutions into your own custom
              collections for easy on-demand access
            </motion.p>
          </div>

          {/* Main content section */}
          <div className="hidden md:block relative  h-[450px] md:h-[420px] lg:h-[856px]">
            <motion.div
              variants={itemVariants}
              className="absolute inset-0 w-full h-full flex justify-center items-center "
            >
              <video
                src={require("../assets/video/topicsMattervideo.mp4")}
                autoPlay
                loop
                muted
                playsInline
                className=" w-full h-full object-contain "
              ></video>
            </motion.div>

            {/* Topic boxes */}
            {topicsThatMatterYou.map((topic, index) => (
              <motion.div
                key={topic.id}
                className={`absolute ${topics[index].className}`}
                variants={itemVariants}
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                animate={{ scale: 1 }}
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 17,
                  mass: 1,
                }}
                onClick={() => navigate(`/category/${topic.slug}`)}
              >
                <div className="group relative">
                  <div className="absolute inset-0 bg-gradient-to-r to-cyan-400 from-purple-800 rounded-lg blur opacity-75 group-hover:opacity-100 transition-opacity duration-200"></div>
                  <div className="p-2 relative bg-gradient-to-r to-cyan-400 from-purple-800 text-white px-20 py-1.5 md:px-4 md:py-2 rounded-lg shadow-lg">
                    <span
                      className={`cursor-pointer px-8 text-[20px] md:text-base lg:text-base font-montserrat font-bold whitespace-nowrap ${
                        topic.name.length > 20
                          ? "text-[8px] md:text-[10px] lg:text-base"
                          : ""
                      }`}
                    >
                      {topic.name}
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <div className="md:hidden flex flex-col py-8 px-3 w-full">
            <div className="grid grid-cols-2 gap-2 mb-6">
              {topicsThatMatterYou.map((topic) => (
                <motion.button
                  variants={fadeIn("up", 0.2)}
                  initial="hidden"
                  whileInView={"show"}
                  viewport={{ once: true, amount: 0.7 }}
                  key={topic.id}
                  onClick={() => navigate(`/category/${topic.slug}`)}
                  className="text-white text-lg py-3 px-2 rounded-lg font-bold text-center bg-gradient-to-r to-cyan-400 from-purple-800 cursor-pointer"
                >
                  {topic.name}
                </motion.button>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default CategorySection;
