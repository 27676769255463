import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-full flex justify-center">
      <div className="font-montserrat px-5 w-full md:w-2/3 py-10 text-secondary">
        <h2 className="text-heading">Privacy Policy</h2>
        <p>
          At Infeedu, your privacy is of utmost importance to us. This Privacy
          Policy outlines how we collect, use, disclose, and safeguard your
          personal information when you use our portal and services. By
          accessing our portal or using our services, you agree to the terms
          outlined in this policy.
        </p>
        <hr />
        <h5 className="text-heading">1. Information We Collect</h5>
        <ul>
          <li>
            <h6>a. Personal Information:</h6>
            <p>
              We may collect personal information, including but not limited to:
            </p>
            <ul>
              <li>
                <p>• Name</p>
              </li>
              <li>
                <p>• Email address</p>
              </li>
              <li>
                <p>• Phone number</p>
              </li>
              <li>
                <p>• Company details</p>
              </li>
              <li>
                <p>• Login credentials</p>
              </li>
            </ul>
          </li>
          <li>
            <h6>b. Non-Personal Information:</h6>
            <p>We also collect non-identifiable information such as:</p>
            <ul>
              <li>
                <p>• IP address</p>
              </li>
              <li>
                <p>• Browser type</p>
              </li>
              <li>
                <p>• Device information</p>
              </li>
              <li>
                <p>• Portal usage statistics</p>
              </li>
            </ul>
          </li>
          <li>
            <h6>c. Cookies and Tracking Technologies:</h6>
            <p>
              We use cookies and similar technologies to enhance user
              experience, analyze portal traffic, and personalize content.
            </p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">2. How We Use Your Information</h5>
        <p>We use the information collected to:</p>
        <ul>
          <li>
            <p>• Provide and manage our services.</p>
          </li>
          <li>
            <p>• Process transactions and send confirmations.</p>
          </li>
          <li>
            <p>
              • Communicate with you regarding your account, updates, and
              promotions.
            </p>
          </li>
          <li>
            <p>• Improve our portal’s functionality and content.</p>
          </li>
          <li>
            <p>• Analyze user behavior to enhance user experience.</p>
          </li>
          <li>
            <p>• Ensure security and prevent fraudulent activities.</p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">
          3. Sharing and Disclosure of Information
        </h5>
        <ul>
          <li>
            <h6>a. With Third Parties:</h6>
            <p>
              We may share your information with trusted third-party service
              providers who assist us in delivering our services, such as
              payment processors or email providers.
            </p>
          </li>
          <li>
            <h6>b. Legal Obligations:</h6>
            <p>
              We may disclose information to comply with legal requirements,
              such as court orders, or to protect our rights and safety.
            </p>
          </li>
          <li>
            <h6>c. Business Transfers:</h6>
            <p>
              In case of a merger, acquisition, or sale of assets, your
              information may be transferred to the involved parties.
            </p>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">4. Data Security</h5>
        <p>
          We implement robust security measures to protect your information from
          unauthorized access, alteration, or disclosure. However, no data
          transmission or storage system can be guaranteed 100% secure.
        </p>
        <hr />
        <h5 className="text-heading">5. Your Choices and Rights</h5>
        <ul>
          <li>
            <h6>a. Access and Updates:</h6>
            <p>
              You have the right to access, update, or delete your personal
              information by logging into your account or contacting us.
            </p>
          </li>
          <li>
            <h6>b. Opt-Out Options:</h6>
            <ul>
              <li>
                <p>
                  • You can opt out of receiving marketing emails by clicking
                  the unsubscribe link in our emails.
                </p>
              </li>
              <li>
                <p>
                  • You can disable cookies through your browser settings,
                  though some features of the portal may be affected.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <hr />
        <h5 className="text-heading">6. Children’s Privacy</h5>
        <p>
          Infeedu does not knowingly collect personal information from
          individuals under the age of 18. If we become aware of such data, we
          will delete it immediately.
        </p>
        <hr />
        <h5 className="text-heading">7. Third-Party Links</h5>
        <p>
          Our portal may contain links to third-party websites. We are not
          responsible for the privacy practices of these external sites. Please
          review their privacy policies before providing any personal
          information.
        </p>
        <hr />
        <h5 className="text-heading">8. International Data Transfers</h5>
        <p>
          If you are accessing our portal from outside the country of operation,
          your information may be transferred to and stored in a country with
          different data protection laws.
        </p>
        <hr />
        <h5 className="text-heading">9. Changes to This Privacy Policy</h5>
        <p>
          We reserve the right to update this Privacy Policy at any time.
          Changes will be posted on this page with a revised effective date.
          Your continued use of the portal after changes are made indicates
          acceptance of the updated policy.
        </p>
        <hr />
        <h5 className="text-heading">10. Contact Us</h5>
        <p>
          If you have any questions or concerns about this Privacy Policy, feel
          free to contact us:
        </p>
        <p className="text-heading underline cursor-pointer">
          <b>Email:</b> privacy@infeedu.com
        </p>
        <hr />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
