import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../utils/constant";
import { toast } from "react-toastify";

const useBookmark = (id, isSavedByUser) => {
  const [isSaved, setIsSaved] = useState(isSavedByUser);
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    setIsSaved(isSavedByUser);
  }, [isSavedByUser]);

  const handleBookmarkClick = async () => {
    if (!token) {
      navigate("/login", { state: { path: location.pathname } });
      return;
    }
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("save", isSaved ? 0 : 1);

      const response = await axios.post(`${apiUrl}/user/save-pdf`, formData, {
        headers: {
          Authorization: `Bearer ${token}`, // Add the token to the headers
        },
      });

      console.log(response);
      if (response.data.status) {
        setIsSaved(!isSaved);
        if (!isSaved) {
          toast.success("Whitepaper Saved");
        } else {
          toast.warning("Removed Whitepaper Saved");
        }
      } else {
        console.error(
          "Failed to save the whitepaper: " + response.data.message
        );
      }
    } catch (error) {
      console.error("An error occurred while saving the whitepaper.", error);
    }
  };

  return { isSaved, handleBookmarkClick };
};

export default useBookmark;
