import React, { useEffect, useState } from "react";
import ProfileSidebar from "../../../components/ProfileSidebar";
import { useSelector } from "react-redux";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import { toast, ToastContainer, Zoom } from "react-toastify";

export default function ProfileNewsletter() {
  const [isSubscriber, setIsSubscriber] = useState(false);

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/user/newsletter-status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.status) {
          setIsSubscriber(!!response.data.data?.isNewsLetterSubscriber);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const onSave = async () => {
    try {
      const formData = new FormData();
      formData.append("isSubscriber", isSubscriber);

      const response = await axios.post(
        `${apiUrl}/user/newsletter-subscribe`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        console.log("success");
        toast.info("Newsletter Updated", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Zoom,
        });
      }
    } catch (error) {
      console.error("Error:", error);
      console.log("An error occurred while saving your subscription.");
    }
  };

  return (
    <>
      <section className="w-full flex">
        <ProfileSidebar />
        <div className="p-5 text-gray-700 w-full font-montserrat">
          <h3 className="text-2xl text-left">Newsletter</h3>
          <div className="flex flex-col gap-4">
            <h3 className="text-3xl text-left font-semibold">
              Subscribed to newsletter
            </h3>
            <select
              name="isSubscriber"
              value={isSubscriber}
              onChange={(e) => setIsSubscriber(e.target.value === "true")}
              className="block w-[200px] rounded-md border-gray-400 bg-white py-2 px-3 text-base shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
            <button
              onClick={onSave}
              className="text-white w-fit py-1 px-5 bg-gradient-to-r from-[#3F179B] to-[#5493A4] rounded"
            >
              Save
            </button>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
