import axios from "axios";
import React, { useState } from "react";
import { ArrowLeft, Mail } from "react-feather";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../utils/constant";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleForgetPassword = async () => {
    if (!email) {
      alert("Please enter email");
    } else {
      try {
        const formData = new FormData();
        formData.append("email", email);

        await axios.post(`${apiUrl}/user/forget-password`, formData);

        navigate(`/login/reset-password?email=${email}`);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <section className="md:h-screen py-20 bg-[#F3F4F6] flex justify-center items-center font-montserrat">
      <div className="w-full p-8 max-w-[448px] bg-white rounded-xl shadow">
        <h4 className="text-2xl font-bold mb-6">Forget Password ?</h4>
        <p className="text-[14px] text-gray-700">
          No worries, we'll send you reset instructions.
        </p>
        <div className="w-full mb-4 p-2 border rounded flex items-center">
          <Mail size={20} className="mr-2 text-gray-600" />
          <input
            onChange={handleEmailChange}
            value={email}
            type="email"
            name="email"
            placeholder="Email"
            required
            className="w-full outline-none"
          />
        </div>
        <button
          onClick={handleForgetPassword}
          className="w-full py-2 bg-gradient-to-r from-teal-400 to-purple-500 text-white rounded-full"
        >
          Continue
        </button>
        <button
          onClick={() => navigate("/login")}
          className="flex items-center text-sm text-gray-500 my-4"
        >
          <ArrowLeft size={20} /> Back to login
        </button>
      </div>
    </section>
  );
}
