//category
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { featuredSolutionData } from "../solutionSets/enum";
import { ChevronRight, Download, Home, Search } from "react-feather";
import PdfPreview from "../../../components/pdfPreview";
import BannerImg from "../../../assets/img/Img7.jpg";
import IconImg from "../../../assets/img/Img2.jpg";
import FeaturedSolution from "../../../components/FeaturedSolution";
import Card from "../../../components/Card";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import useSubscribe from "../../../hooks/useSubscribe";
import { useSelector } from "react-redux";
import { Slide, ToastContainer } from "react-toastify";
import SearchBar from "../../../components/SearchBar";

const Category = () => {
  const { categorySlug } = useParams();
  const [categoryData, setCategoryData] = useState({});
  const { isSubscribe, handleSubscribeClick } = useSubscribe(
    categoryData?.id,
    categoryData?.isSubscribe ?? false
  );
  const token = useSelector((state) => state.user.token);
  const [imgSrc, setImgSrc] = useState(categoryData?.bannerPath);

  useEffect(() => {
    if (categoryData?.bannerPath) {
      setImgSrc(categoryData.bannerPath);
    }
  }, [categoryData]);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/category/getsolution-setsbycategory/slug/${categorySlug}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the headers
            },
          }
        );
        if (response.data.status) {
          setCategoryData({
            ...response.data.data.category,
            whitepapers: response.data.data.solutionSets,
            isSubscribe: response.data.data.isSubscribe,
          });
        }
      } catch (error) {
        console.log("An error occurred while getting Category.");
      }
    };

    getCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return categoryData?.id ? (
    <>
      <header className="w-full bg-white shadow-lg rounded-lg p-3 px-4">
        <div className="flex items-center gap-2 text-gray-700">
          <Link to="/home">
            <Home className="text-gray-700" size={20} />
          </Link>
          <ChevronRight className="text-gray-700" size={20} />
          <span className="text-gray-700 text-sm no-underline hover:cursor-pointer">
            CATEGORY
          </span>
        </div>
      </header>

      <div className="p-3">
        {/* Banner Image */}
        <div className="mb-3 relative w-full h-[70vh] overflow-hidden p-6 rounded-3xl object-contain font-montserrat">
          <div className="">
            <img
              src={imgSrc}
              alt={categoryData?.name}
              onError={() => {
                setImgSrc(
                  "https://images.pexels.com/photos/5749149/pexels-photo-5749149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                );
              }}
              className="w-full h-full absolute top-0 left-0 rounded-3xl object-cover"
            />
          </div>
          <div className="relative bg-opacity-90 sm:p-8 flex flex-col h-full w-full justify-end rounded-3xl">
            <div className="text-white space-y-2 w-full sm:w-2/3">
              <h1 className="mx-2 text-2xl sm:text-4xl font-bold">
                {categoryData?.name}
              </h1>
              <p className="mx-2 text-sm sm:text-base line-clamp-6">
                {categoryData?.descp || "Description not available."}
              </p>
            </div>

            <div className="mx-2 flex items-center w-full mt-2">
              <button
                className="px-4 py-2 bg-teal-500 text-white rounded-full text-sm sm:text-base hover:bg-teal-600"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSubscribeClick();
                }}
              >
                {isSubscribe ? "Unsubscribe" : "Subscribe"}
              </button>
              {/* <div className="text-white text-sm sm:text-base ml-4">
                {categoryData?.whitepapers?.length} WHITEPAPERS
              </div> */}
            </div>
          </div>
        </div>

        {/* Solutions and Search Bar */}
        <div className="bg-teal-700 w-full p-4 flex flex-col md:flex-row justify-between items-center rounded-md space-y-4 md:space-y-0">
          <div className="text-white text-lg md:text-xl font-semibold">
            {categoryData?.whitepapers?.length} Whitepapers
          </div>

          <div className="flex justify-between items-center bg-white rounded-full px-3 py-2 ">
            {/* <input
              type="text"
              placeholder="Search..."
              className="bg-transparent outline-none text-gray-700 flex-grow"
            /> */}
            <SearchBar />
            <Search className="text-gray-500" size={20} />
          </div>
        </div>

        {/* PDF Preview */}
        {/* <PdfPreview /> */}

        {/* Featured Solutions */}
        {/* <FeaturedSolution /> */}

        {/* Solutions Grid */}
        <h3 className="p-3">Whitepapers</h3>
        {/* <div className="flex flex-wrap justify-center">
          {featuredSolutionData.map((featureCard) => (
            <Card key={featureCard.title} featureCard={featureCard} />
          ))}
        </div> */}
        <div className="flex flex-wrap justify-center">
          {categoryData?.whitepapers?.map((whitepaper) => (
            <Card
              key={whitepaper?.solutionSet.id}
              featureCard={whitepaper}
              categaryTitle={categoryData?.name}
            />
          ))}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Slide}
      />
    </>
  ) : null;
  // );
};

export default Category;
