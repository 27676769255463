import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiUrl } from "../../../utils/constant";
import { useSelector } from "react-redux";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();

  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    const getCategory = async () => {
      try {
        const response = await axios.get(`${apiUrl}/blogs/get-blogs`, {
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the headers
          },
        });
        if (response.data.status) {
          setBlogs(response.data?.data || []);
        }
      } catch (error) {
        console.log("An error occurred while getting blogs.");
      }
    };

    getCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6">
        {blogs.map((item, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-lg">
            <img
              src={item.blogImage}
              alt={item.blogName}
              className="w-full h-80 object-cover mb-4 rounded"
            />
            <h3 className="font-bold text-xl mb-2">{item.blogName}</h3>
            <p className="text-gray-600 text-lg mb-4">
              {/* <div dangerouslySetInnerHTML={{ __html: item.blogContent }} /> */}
            </p>
            <button
              onClick={() => {
                if (!token) {
                  navigate("/login", { state: { path: location.pathname } });
                  return;
                } else {
                  navigate(`/blogview/${item.id}`);
                }
              }}
              className="w-full py-2 bg-gradient-to-r to-teal-400 from-purple-500 text-white rounded-full"
            >
              Read More
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
