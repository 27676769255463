const TermsAndCondition = () => {
  return (
    <div className="font-montserrat px-5 md:px-20 py-10">
      <p>
        These Terms and Conditions outline the rules and regulations for the use
        of our portal, services, and website located at infeedu.com. By
        accessing or using our services, you agree to comply with these terms.
      </p>
      <hr />
      <h5>1. Acceptance of Terms</h5>
      <p>
        By accessing our portal, you confirm that you have read, understood, and
        agree to these Terms and Conditions. If you do not agree, you may not
        use our services.
      </p>
      <hr />
      <h5>2. Definitions</h5>
      <ul>
        <li>
          <p>
            • <b>"Portal"</b> refers to Infeedu’s website and associated
            services.
          </p>
        </li>
        <li>
          <p>
            • <b>"User"</b> refers to any individual or entity accessing the
            portal. • "Content" includes whitepapers, research, data, and other
            information available on the portal.
          </p>
        </li>
        <li>
          <p>
            • <b>"Content"</b> includes whitepapers, research, data, and other
            information available on the portal.
          </p>
        </li>
      </ul>
      <hr />
      <h5>3. Use of the Portal</h5>
      <ul>
        <li>
          <p>• You must be 18 years or older to use our portal.</p>
        </li>
        <li>
          <p>
            • You agree not to misuse the portal, including uploading harmful or
            unlawful content.
          </p>
        </li>
        <li>
          <p>
            • You may not use the portal for any unauthorized commercial
            activities.
          </p>
        </li>
      </ul>
      <hr />
      <h5>4. Account Registration and Security</h5>
      <ul>
        <li>
          <p>
            • You must provide accurate and complete information during
            registration.
          </p>
        </li>
        <li>
          <p>
            • You are responsible for safeguarding your account credentials and
            all activities conducted under your account.
          </p>
        </li>
        <li>
          <p>
            • Notify us immediately of any unauthorized access or security
            breaches.
          </p>
        </li>
      </ul>
      <hr />
      <h5>5. Intellectual Property</h5>
      <ul>
        <li>
          <p>
            • All content on the portal, including whitepapers, designs, logos,
            and text, is the intellectual property of Infeedu or its licensors.
          </p>
        </li>
        <li>
          <p>
            • You may not copy, distribute, or modify any content without prior
            written consent.
          </p>
        </li>
      </ul>
      <hr />
      <h5>6. Content Usage</h5>
      <ul>
        <li>
          <p>
            • Content available on the portal is for informational purposes only
            and does not constitute professional advice.
          </p>
        </li>
        <li>
          <p>
            • You may download and use content for personal or organizational
            purposes but not for redistribution without permission.
          </p>
        </li>
      </ul>
      <hr />
      <h5>7. Vendor Contributions</h5>
      <ul>
        <li>
          <p>
            • Vendors providing content retain ownership of their submissions
            but grant Infeedu a non-exclusive license to display, distribute,
            and promote their content.
          </p>
        </li>
        <li>
          <p>
            • Vendors are responsible for ensuring that their submissions comply
            with applicable laws and do not infringe on third-party rights.
          </p>
        </li>
      </ul>
      <hr />
      <h5>8. Privacy</h5>
      <p>
        Our use of your data is governed by our Privacy Policy. By using our
        portal, you agree to the terms outlined in the Privacy Policy.
      </p>
      <hr />
      <h5>9. Limitation of Liability</h5>
      <ul>
        <li>
          <p>
            • Infeedu is not responsible for inaccuracies, errors, or omissions
            in the content provided.
          </p>
        </li>
        <li>
          <p>
            • We shall not be held liable for any damages resulting from your
            use of the portal or reliance on its content.
          </p>
        </li>
      </ul>
      <hr />
      <h5>10. Termination</h5>
      <p>
        We reserve the right to terminate or suspend your access to the portal
        at any time, without prior notice, for violations of these terms or
        other reasons at our discretion.
      </p>
      <hr />
      <h5>11. Amendments</h5>
      <p>
        Infeedu reserves the right to update or change these Terms and
        Conditions at any time. Continued use of the portal after such updates
        constitutes acceptance of the revised terms.
      </p>
      <hr />
      <h5>12. Governing Law</h5>
      <p>
        These Terms and Conditions are governed by the laws of [Your
        Country/State]. Any disputes arising from the use of the portal shall be
        resolved in the courts of [Your Location].
      </p>
      <hr />
      <h5>13. Contact Us</h5>
      <p>
        If you have any questions or concerns about these Terms and Conditions,
        please contact us at:
      </p>
      <p>
        <b>Email</b>: support@infeedu.com
      </p>
      <hr />
    </div>
  );
};

export default TermsAndCondition;
